<template>
  <div style="height: 100%;" class="container">
    <van-nav-bar title="店铺详情" left-arrow @click-left="goBack" fixed />

    <div class="storeInfo">
      <span class="storeInfo-img">
        <van-image round width="1.0811rem" height="1.0811rem" fit="cover" :src="shopInfo.imgUrl" />
      </span>

      <div class="storeInfo-name">
        <p>{{ shopInfo.name }}</p>
        <div class="storeInfo-name-div">
          <div v-if="shopInfo.grade == 0">
            <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index" src="./xing.png"
              alt="">
          </div>
          <div v-if="0 < shopInfo.grade && shopInfo.grade < 6">
            <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index" src="./zuan.png"
              alt="">
          </div>
          <div v-if="5 < shopInfo.grade && shopInfo.grade < 11">
            <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index"
              src="./blueGuan.png" alt="">
          </div>
          <div v-if="10 < shopInfo.grade">
            <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index" src="./guan.png"
              alt="">
          </div>
        </div>
      </div>

      <div class="storeInfo-collect">
        <div class="storeInfo-collect-start" v-show="userCollect == '0'" @click="collectShop">
          <van-icon size="25" name="star-o" color="#E54320" />
          <span style="color: #E54320;">收藏</span>
        </div>
        <div class="storeInfo-collect-start" v-show="userCollect == '1'" @click="collectShop">
          <van-icon color="#E54320" size="25" name="star" />
          <span style="color: #E54320;">已收藏</span>
        </div>
      </div>
    </div>

    <div class="ticket">
      <div class="ticket-left">
        <span class="ticket-left-item" v-for="x in coupons" :key="x.id">
          <van-tag plain color="#E54320" size="medium">{{ x.name }}</van-tag>
        </span>
      </div>
      <div class="ticket-right">
        <van-button round color="#E54320" size="mini" @click="lingqv">
          <span style="font-size: 12px;">领取</span>
          <van-icon name="arrow" />
        </van-button>
      </div>
    </div>

    <div class="storeTitle">
      <img src="./title.png" alt="">
    </div>

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="shopList">
        <div class="shopList-item" v-for="item in records" :key="item.id" @click="enterDetail(item.id)">
          <span>
            <van-image width="155" height="155" lazy-load radius="5" fit="cover" :src="item.picUrls[0]" />
          </span>

          <div class="shopList-item-main">{{ item.name }}</div>

          <div class="shopList-item-footer">
            <div class="shopList-item-footer-price">
              <span style="color: #E54320;">
                <span style="font-size: 0.3784rem;">{{ item.skus[0].ricePrice }}驿马</span>
              </span>
              <span style="color: #999; font-size: 0.3784rem; margin-left: 0.1351rem; text-decoration: line-through;">￥{{
                item.skus[0].salesPrice }}</span>
            </div>

            <div style="color: #999;">累计兑换{{ item.saleNum }}件</div>
          </div>
        </div>
      </div>
    </van-list>

    <ticket-list 
      :ticketBoolean="ticketBoolean" 
      :coupons="coupons" 
      :riceivedCoupons="riceivedCoupons" 
      @updatePage="updatePage"
      @closeTicket="closeTicket" />

  </div>
</template>

<script>
import { getStoreDetail, collectShop } from '@/api/detail'
import { getTickerList } from "@/api/flMall"
import TicketList from "./actionSheet/TicketList"
export default {
  components: {
    TicketList
  },

  data() {
    return {
      records: [],
      gradeList: [],//店铺星星颗数
      shopInfo: {},
      userCollect: '0',
      isComfirm: false,
      loading: false,
      finished: false,
      params: {
        current: 1,
        size: 10,
      },
      coupons: [],
      riceivedCoupons: [],
      ticketBoolean: false
    }
  },

  created() {
    if (this.$route.query.session) {
      this.$store.commit('setSession', this.$route.query.session)
    }
  },

  mounted() {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
  },

  methods: {
    getStoreDetail() {
      let params = JSON.parse(JSON.stringify(this.params))
      params.shopId = this.$route.query.id
      getStoreDetail(params).then(res => {
        if (res.data.code === 0) {
          this.getTickerList({ shopId: res.data.data.shopInfo.id })
          if (res.data.data.GoodsSpu.records.length) {
            this.records = [...this.records, ...res.data.data.GoodsSpu.records]
            this.loading = false
          } else {
            this.finished = true
          }
          this.shopInfo = res.data.data.shopInfo
          //星钻皇冠
          if (this.shopInfo.grade <= 0 || this.shopInfo.grade == null) {
            for (let index = 0; index < 5; index++) {
              this.gradeList[index] = 1
            }
          }
          if (this.shopInfo.grade > 0 && this.shopInfo.grade < 6) {
            let strGrade = this.shopInfo.grade
            for (let index = 0; index < strGrade; index++) {
              this.gradeList[index] = 1
            }
          }
          if (this.shopInfo.grade > 5 && this.shopInfo.grade < 11) {
            let strGrade = this.shopInfo.grade - 5
            for (let index = 0; index < strGrade; index++) {
              this.gradeList[index] = 1
            }
          }
          if (this.shopInfo.grade > 10) {
            let strGrade = this.shopInfo.grade - 10
            for (let index = 0; index < strGrade; index++) {
              this.gradeList[index] = 1
            }
          }
          this.userCollect = res.data.data.userCollect
          this.isComfirm = false
        }
      })
    },

    enterDetail(id) {
      this.$router.push({
        path: '/milimallDetail',
        query: {
          id,
          session: this.$route.query.session
        }
      })
    },

    goBack() {
      if (this.$route.query.type == 'h5') {
        this.$router.go(-1)
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
    },

    collectShop() {
      // 防重提交
      if (this.isComfirm) {
        return
      }
      let data = {
        type: '2',
        relationId: this.shopInfo.id
      }
      collectShop(data).then(res => {
        this.isComfirm = true
        if (res.data.code === 0) {
          this.getStoreDetail()
        }
      })
    },

    onLoad() {
      this.getStoreDetail()
      this.params.current++
    },

    getTickerList (params) {
      getTickerList(params).then(res => {
        if (res.data.code === 0) {
          this.coupons = res.data.data.coupons
          this.riceivedCoupons = res.data.data.riceivedCoupons
        }
      })
    },

    lingqv () {
      this.ticketBoolean = true
    },

    updatePage () {
      this.getStoreDetail()
    },

    closeTicket () {
      this.ticketBoolean = false
    }
  }
}
</script>

<style lang="less" scoped>
.van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  .van-nav-bar__left,.van-nav-bar__right {
    padding: 0 10px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  padding-top: 1.2432rem;
  .van-list {
    flex: 1;
  }
}

.storeInfo {
  padding: 0.4054rem 0.4054rem 0;
  background: #fff;
  display: flex;
  align-items: center;

  &-img {
    .van-image {
      /deep/ .van-image__img {
        height: 1.0811rem !important;
      }
    }
  }

  &-name {
    flex: 1;
    margin-left: 0.1351rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    color: #fff;

    &-p {
      font-size: 0.3784rem;
    }

    &-div {
      max-width: 50%;
      font-size: 0.3243rem;
      // background: #F0F0F0;
      padding: 0.0541rem;
      // border-radius: 999px;

      div {
        transform: scale(.8);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  &-collect {
    width: 1.0811rem;

    &-start {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 0.3243rem;
        transform: scale(.8);
      }
    }
  }
}

.storeTitle {
  padding: 0.2703rem 0.4054rem;
}

.shopList {
  height: 100%;
  padding: 0 0.1351rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;

  &-item {
    width: 4.7297rem;
    height: 8.1081rem;
    background: #fff;
    padding: 0.2703rem;
    margin-bottom: 0.2703rem;
    box-sizing: border-box;
    border-radius: 0.1351rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-main {
      font-size: 0.3784rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    &-footer {
      &-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .van-button--mini {
          padding: 0 0.3243rem;
        }
      }
    }
  }
}
.ticket {
  display: flex;
  align-items: center;
  padding: 0 0.4054rem 0.4054rem;
  background: #fff;
  &-left {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: auto;
    &-item {
      margin-right: 3px;
      flex-shrink: 0;
    }
  }
  &-left::-webkit-scrollbar {
    display: none;
  }
  &-right {
    transform: translateY(-2px);
    .van-button--mini {
      padding: 0 6px;
    }
  }
}
</style>